<template>
  <v-progress-linear
    rounded
    :dark="avance === 100"
    :value="avance"
    :color="avance === 100 ? 'success' : 'pendiente'"
    height="22"
  >
    <template v-slot:default="{ value }">
      <strong style="font-size: 13px">{{ value.toFixed(2) }}%</strong>
    </template>
  </v-progress-linear>
</template>
<script>
export default {
  name: "ProgresoPorcentajeComponent",
  props: ["avance"],
};
</script>
<style lang="scss" scoped>
.styleText {
  color: white;
}
</style>
