<template>
  <section>
    <v-row justify="start">
      <v-col cols="12">
        <p>Agregue la cantidad de etapas que desee hasta completar el 100%</p>
        <progreso-porcentaje-component :avance="avanceActualComputed" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-btn
          color="secondary"
          class="mb-2"
          :disabled="avanceActualComputed == 100"
          @click="goToEtapaConfig()"
          >Agregar etapas</v-btn
        >

        <v-data-table
          :headers="headers"
          :items="etapasList"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.porcentaje`]="{ item }">
            {{ (item.porcentaje * 100).toFixed(2) + "%" }}
          </template>
          <template v-slot:[`item.fecha_inicio`]="{ item }">
            {{ moment(item.fecha_inicio).format("DD/MM/YYYY hh:mm") }}
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            {{ moment(item.fecha_fin).format("DD/MM/YYYY hh:mm") }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="editItem(item)">
                  mdi-cog
                </v-icon>
              </template>
              <span> Configurar </span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
          <template v-slot:no-data> No se han agregado etapas </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="start">
      <div class="mt-5">
        <v-btn
          v-if="Object.keys(this.contratoExistente).length > 0"
          class="ml-2 mr-2"
          color="secondary"
          outlined
          @click="volverAnterior()"
        >
          Anterior
        </v-btn>
      </div>
    </v-row>
    <ConfirmationDialogComponent
      :show="dialogDelete"
      btnConfirmar="Continuar"
      title="¿Está seguro que desea eliminar esta etapa?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="dialogDelete = false"
      @confirm="deleteItemConfirm()"
    />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ProgresoPorcentajeComponent from "./ProgresoPorcentajeComponent.vue";
import moment from "moment";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
export default {
  components: {
    ProgresoPorcentajeComponent,
    ConfirmationDialogComponent,
  },
  data: () => ({
    dialogDelete: false,
    headers: [
      { text: "Etapa", align: "start", value: "etapa" },
      { text: "Descripción", align: "start", value: "descripcion" },
      { text: "Porcentaje", value: "porcentaje", align: "start" },
      { text: "Fecha Inicio", value: "fecha_inicio", align: "start" },
      { text: "Fecha Fin", value: "fecha_fin", align: "start" },
      { text: "Acciones", align: "center", value: "actions", sortable: false },
    ],
    editeId: null,
  }),

  computed: {
    ...mapState("procesoCompra", [
      "idOrdenCompra",
      "etapasList",
      "etapaProgreso",
      "contratoExistente",
    ]),
    avanceActualComputed() {
      return parseFloat(this.etapaProgreso.progreso) * 100;
    },
  },

  methods: {
    ...mapMutations("procesoCompra", ["setEtapaEditable", "setEtapa"]),
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    volverAnterior() {
      if (Object.keys(this.contratoExistente).length > 0) {
        this.$router.back();
      } else {
        this.$emit("volverStep1");
      }
    },
    goToEtapaConfig() {
      this.$router.push("/proceso-compra/crear-etapa-contrato");
    },
    async editItem(item) {
      const { status, data } =
        await this.services.ContratoService.getContratoEtapa(item.id);

      if (status == 200) {
        this.setEtapaEditable(true);
        this.setEtapa(data);
        this.$router.push("/proceso-compra/crear-etapa-contrato");
      }
    },

    deleteItem(item) {
      this.editeId = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const { status } = await this.services.ContratoService.deleteEtapa(
        this.editeId
      );

      if (status == 204) {
        this.getEtapasContrato();
        this.getEtapasProgreso();
        this.temporalAlert({
          show: true,
          message: "Etapa eliminada correctamente",
          type: "success",
        });
        this.dialogDelete = false;
      }
    },
  },
  created() {},
};
</script>
