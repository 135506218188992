<template>
  <section>
    <v-row>
      <v-col cols="12" md="5" sm="12">
        <v-form lazy-validation>
          <v-select
            :items="tipoContratos"
            outlined
            item-value="id"
            item-text="nombre"
            label="Tipo de compra"
            v-model="formulario.id_tipo_contrato"
            :error-messages="tipoContratoErrors"
            @blur="$v.formulario.id_tipo_contrato.$touch()"
            @change="validarNumeroContrato"
          ></v-select>
          <v-text-field
            outlined
            readonly
            :label="formLabel('numero')"
            v-model="numeroContrato"
            :error-messages="numeroErrors"
            @input="$v.formulario.numero_contrato.$touch()"
          />
          <v-text-field
            outlined
            :label="formLabel('numero_interno')"
            v-model="formulario.numero_contrato_interno"
            :error-messages="numeroInternoErrors"
            @input="$v.formulario.numero_contrato_interno.$touch()"
          />
          <v-text-field
            outlined
            label="Nombre del contrato *"
            counter="100"
            maxLength="100"
            v-model="formulario.nombre_contrato"
            :error-messages="nombreErrors"
            @input="$v.formulario.nombre_contrato.$touch()"
          />
          <vc-date-picker
            v-model="formulario.fecha_hora_contratacion"
            mode="dateTime"
            locale="es"
            :popover="config_calendar"
          >
            <template v-slot="{ inputEvents }">
              <v-text-field
                class="py-1 border roundend focus:outline-none focus:border-blue-300"
                :label="formLabel('fecha')"
                readonly
                :value="getFechaFormat"
                v-on="inputEvents"
                outlined
                :error-messages="fechaErrors"
                @input="$v.formulario.fecha_hora_contratacion.$touch()"
                @blur="$v.formulario.fecha_hora_contratacion.$touch()"
              />
            </template>
          </vc-date-picker>

          <v-text-field
            outlined
            readonly
            label="Monto adjudicado"
            prepend-inner-icon="mdi-currency-usd"
            placeholder="00.00"
            v-model="montoFinalAdjudicado"
            :error-messages="montoErrors"
            @input="$v.formulario.monto_adjudicado.$touch()"
          />

          <v-row class="d-flex align-items-center justify-content-between">
            <v-col cols="4" sm="2" md="6">
              <v-text-field
                :suffix="isSwitchActive ? transformaUnidades('Meses') : transformaUnidades('Días')"
                outlined
                label="Plazo contractual *"
                v-mask="'###'"
                v-model="formulario.plazo_contractual"
                :error-messages="plazoErrors"
                @input="$v.formulario.plazo_contractual.$touch()"
              />
            </v-col>

            <v-col cols="4" sm="1" md="2">
                <v-switch 
                  color="secondary" 
                  inset
                  v-model="isSwitchActive"
                ></v-switch>
            </v-col>

            <v-col cols="4" sm="3" md="4">
              <span v-if="isSwitchActive">{{ 'Equivalente a: ' + this.diasEquivalentes + ' días' }}</span>
            </v-col>
          </v-row>

          <template v-if="formulario.id_tipo_contrato == 2">
            <v-select
              :items="copiasA"
              chips
              multiple
              outlined
              label="Copias a emitir"
              v-model="formulario.copias"
            ></v-select>
          </template>
          <template>
            <v-file-input
              outlined
              prepend-icon=""
              placeholder="Contrato"
              label="Archivo"
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
              v-model="formulario.contrato"
            />
          </template>
          <v-row align="center">
            <v-col>
              <v-btn outlined @click="regresar()">Cancelar</v-btn>
            </v-col>
            <v-col>
              <v-btn color="secondary" @click="continuar()">Crear</v-btn>
            </v-col>
          </v-row>
          <v-dialog ref="dialog" v-model="modalReloj" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="modalReloj"
                v-model="formulario.horaVigente"
                label="Hora"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modalReloj"
              v-model="formulario.horaVigente"
              full-width
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalReloj = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="modalReloj = false">
                Establecer
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-form>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { helpers, required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ0-9-()#_\u00f1\u00d1 ]*$/);

const contratoName = helpers.regex(
  "contratoName",
  /^[a-zA-ZÀ-ÿ-()\u00f1\u00d1 ]*$/
);

export default {
  data() {
    return {
      isSwitchActive: false,
      diasEquivalentes: 0,
      validar: true,
      menu: false,
      modalReloj: false,
      monto_adjudicado: "",
      copiasA: ["UCP", "Contratista", "Finanzas"],
      formulario: {
        numero_contrato: "",
        numero_contrato_interno: "",
        nombre_contrato: "",
        fecha_hora_contratacion: "",
        monto_adjudicado: "",
        plazo_contractual: "",
        copias: [],
        id_ganador: null,
        id_tipo_contrato: null,
        contrato: null,
      },
      fecha_actual: moment().format("YYYY/MM/DD hh:mm"),
      rules: {
        diasAño: [(v) => v < 366 || "Limite de dias 366"],
      },
      tipoContratos: [],
      config_calendar: {
        visibility: "click",
      },
    };
  },
  props: {
    numeroOrdenCompra: null,
    montoAdjudicado: null,
  },
  validations: {
    formulario: {
      numero_contrato: { required, alpha },
      numero_contrato_interno: { required, alpha },
      nombre_contrato: { required, alpha },
      fecha_hora_contratacion: { required },
      monto_adjudicado: { required },
      plazo_contractual: { required },
      copias: requiredIf(function () {
        return this.$route.params.tipo == 2 || this.$route.params.tipo == 3;
      }),
      id_tipo_contrato: { required },
    },
  },
  computed: {
    ...mapState("agregarInsumo", ["proveedorInfo"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    numeroErrors() {
      const errors = [];
      if (!this.$v.formulario.numero_contrato.$dirty) return errors;
      !this.$v.formulario.numero_contrato.required &&
        errors.push("El campo es obligatorio");
      !this.$v.formulario.numero_contrato.alpha &&
        errors.push("El campo  solo permite caracteres alfanuméricos");
      return errors;
    },
    numeroInternoErrors() {
      const errors = [];
      if (!this.$v.formulario.numero_contrato_interno.$dirty) return errors;
      !this.$v.formulario.numero_contrato_interno.required &&
        errors.push("El campo es obligatorio");
      !this.$v.formulario.numero_contrato_interno.alpha &&
        errors.push("El campo  solo permite caracteres alfanuméricos");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.formulario.nombre_contrato.$dirty) return errors;
      !this.$v.formulario.nombre_contrato.required &&
        errors.push("El campo es obligatorio");
      !this.$v.formulario.nombre_contrato.alpha &&
        errors.push("El campo  solo permite caracteres alfanuméricos");
      return errors;
    },
    fechaErrors() {
      const errors = [];
      if (!this.$v.formulario.fecha_hora_contratacion.$dirty) return errors;
      !this.$v.formulario.fecha_hora_contratacion.required &&
        errors.push("El campo es obligatorio");
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.formulario.monto_adjudicado.$dirty) return errors;
      !this.$v.formulario.monto_adjudicado.required &&
        errors.push("El campo es obligatorio");
      return errors;
    },
    plazoErrors() {
      const errors = [];
      if (!this.$v.formulario.plazo_contractual.$dirty) return errors;
      !this.$v.formulario.plazo_contractual.required &&
        errors.push("El campo es obligatorio");
      return errors;
    },
    tipoContratoErrors() {
      const errors = [];
      if (!this.$v.formulario.id_tipo_contrato.$dirty) return errors;
      !this.$v.formulario.id_tipo_contrato.required &&
        errors.push("El campo es obligatorio");
      return errors;
    },
    getFechaFormat() {
      if (this.formulario.fecha_hora_contratacion) {
        return moment(this.formulario.fecha_hora_contratacion).format(
          "DD/MM/YYYY hh:mm a"
        );
      } else return "";
    },
    numeroContrato() {
      return this.numeroOrdenCompra || this.formulario.numero_contrato;
    },
    montoFinalAdjudicado() {
      if (this.montoAdjudicado > 0) {
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(this.montoAdjudicado)
      } else {
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }).format(this.formulario.monto_adjudicado)
      }
    },
  },

  methods: {
    transformaUnidades(unidad) {
      if (unidad === 'Meses' && this.isSwitchActive) {
        const meses = (this.formulario.plazo_contractual) || 0;
        this.diasEquivalentes = Math.floor(meses * 30.417);

      } else if (unidad === 'Días' && !this.isSwitchActive) {
        this.diasEquivalentes = this.formulario.plazo_contractual;
      }

      return unidad;
    },

    configurarHora() {
      this.modalReloj = true;
    },
    formLabel(key) {
      let contratoLabels = {
        numero: "Número de contrato",
        numero_interno: "Número de contrato interno *",
        fecha: "Fecha y hora de contratación *",
      };
      let ordenCompraLabels = {
        numero: "Número de orden de compra",
        numero_interno: "Número interno de orden de compra *",
        fecha: "Vigencia a partir de *",
      };

      switch (this.formulario.id_tipo_contrato) {
        case 1:
          return contratoLabels[key];
        case 2:
          return ordenCompraLabels[key];
        default:
          return contratoLabels[key];
      }
    },
    regresar() {
      this.$router.back();
    },

    validacionCantidad(e) {
      if (
        (window.event.keyCode >= 48 && window.event.keyCode <= 57) ||
        window.event.keyCode === 46
      ) {
        return;
      }
      e.preventDefault();
    },
    async getTiposContrato() {
      const { data, status } =
        await this.services.ContratoService.getTipoContrato();
      if (status == 200) {
        this.tipoContratos = data;
      }
    },
    continuar() {
      this.formulario.numero_contrato = this.numeroContrato;
      this.formulario.monto_adjudicado = this.montoFinalAdjudicado;
      this.formulario.fecha_hora_contratacion = moment(
        this.formulario.fecha_hora_contratacion
      ).format("YYYY/MM/DD hh:mm");
      this.formulario.id_ganador = this.proveedorInfo.id_ganador;
      this.formulario.plazo_contractual = this.diasEquivalentes;
      this.$emit("continuar", this.formulario);
      //Se limpia el valor de la transformacion de meses a dias
      this.formulario.plazo_contractual = null;
    },
    formatearPrecio(cantidad) {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(cantidad);
    },
    async validarNumeroContrato() {
      const idGanador =
        this.proveedorInfo?.id_ganador ||
        this.seguimientoContrato?.contrato?.id_ganador;

      const response =
        await this.services.ContratoService.getGanadorProveedorInfo(idGanador, {
          tipo_contrato: this.formulario.id_tipo_contrato,
        });
      if (response?.status == 200) {
        this.formulario.numero_contrato = response?.data?.numero_orden_compra;
        this.formulario.monto_adjudicado = Number(
          response?.data?.total_sub_procesos
        );
      }
    },
  },
  created() {
    this.getTiposContrato();
  },
};
</script>

<style></style>
