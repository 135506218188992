<template>
  <section>
    <div
      class="d-flex flex-wrap mx-2 px--md-4 px-sm-0 align-center justify-md-space-between justify-sm-center"
    >
      <p class="text-h6 secondary--text mb-0">Creación de contrato</p>
      <!-- <v-btn
        :disabled="avanceActualComputed < 100"
        @click="showDialog = true"
        color="success"
        class="white--text"
        >Crear contrato</v-btn
      > -->
    </div>

    <div class="mx-md-4 mx-sm-0">
      <info-producto-component
        :showEyeButton="[1, 2].includes($route.params.tipo)"
        :tipoProceso="Number($route.params.tipo)"
        @setNumeroOrdenCompra="setNumeroOrdenCompra"
        @setMontoAdjudicado="setMontoAdjudicado"
      />
    </div>

    <crear-orden-compra-component
      ref="contratoForm"
      @continuar="createContrato"
      :numeroOrdenCompra="ordenCompraCodigo"
      :montoAdjudicado="montoAdjudicadoOrden"
    />

    <ConfirmationDialogComponent
      :show="showDialog"
      btnConfirmar="Continuar"
      title="¿Está seguro que desea crear el contrato?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="showDialog = false"
      @confirm="changeEstadoContrato"
    />
  </section>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import infoProductoComponent from "@/components/InfoProductoComponent.vue";
import crearOrdenCompraComponent from "./components/CrearOrdenCompraComponent.vue";
import crearEtapasOrdenCompraComponent from "./components/CrearEtapasOrdenCompraComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "crearContratoCompraView",
  components: {
    infoProductoComponent,
    crearOrdenCompraComponent,
    crearEtapasOrdenCompraComponent,
    ConfirmationDialogComponent,
  },
  data: () => ({
    step: 1,
    showDialog: false,
    ordenCompraCodigo: null,
    montoAdjudicadoOrden: 0,
  }),
  computed: {
    ...mapState("procesoCompra", [
      "etapaProgreso",
      "idOrdenCompra",
      "contratoExistente",
    ]),
    ...mapState("agregarInsumo", ["selectedProvider", "proveedorInfo"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    avanceActualComputed() {
      if (isNaN(this.etapaProgreso.progreso)) return 0;
      else return parseFloat(this.etapaProgreso.progreso) * 100;
    },
  },
  watch: {},
  methods: {
    ...mapMutations("procesoCompra", [
      "setIdOrdenCompra",
      "setContratoExistente",
    ]),
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    async createContrato(form) {
      this.$refs.contratoForm.$v.$touch();
      if (!this.$refs.contratoForm.$v.$invalid) {
        let formData = new FormData();

        Object.keys(form).forEach((key) => {
          formData.append(key, form[key]);
        });


        const { status, data } =
          await this.services.ContratoService.postContrato(formData)
        if (status == 201) {
          await this.changeEstadoContrato(data.id);

          // this.setIdOrdenCompra(data.id);
          // await this.getEtapasContrato();
          // this.getEtapasProgreso();
          // this.step = 2;
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "Existen campos obligatorios vacíos",
          type: "warning",
        });

      }
    },
    async goToEtapas() {
      this.setIdOrdenCompra(this.contratoExistente.id);
      await this.getEtapasContrato();
      this.getEtapasProgreso();
      this.step = 2;
    },
    async changeEstadoContrato(idOrdenCompra) {
      const { status } =
        await this.services.ContratoService.updateEstadoContrato(
          idOrdenCompra,
          { id_estado_contrato: 2 }
        );

      if (status == 204) {
        if (this.haveRole("ROLE_UACI")) {
          this.$router.replace("/pac-procesos");
        } else {
          this.$router.replace({
            name: "seguimiento-orden-compra",
            params: { idContrato: Number(idOrdenCompra) },
          });
        }
      }
    },
    setNumeroOrdenCompra(value) {
      this.ordenCompraCodigo = value;
    },
    setMontoAdjudicado(value) {
      this.montoAdjudicadoOrden = value.replace(",", "");
    },
  },
  mounted() {},
  created() {
    if (
      (this.proveedorInfo?.id_ganador ||
        this.seguimientoContrato?.contrato?.id_ganador) === undefined
    ) {
      if (this.$route.params.tipo == 2 || this.$route.params.tipo == 3) {
        this.$router.replace({ name: "dashboard" });
      } else {
        this.$router.back();
      }
    }
  },
  beforeDestroy() {
    this.setContratoExistente({});
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/proceso-compra/crear-etapa-contrato") {
        vm.step = 2;
        vm.getEtapasContrato();
        vm.getEtapasProgreso();
      }
    });
  },
};
</script>
<style scoped lang="scss">
.v-stepper__header {
  box-shadow: none !important;
}
.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: white !important;
}

@media (min-width: 640px) {
  .v-stepper__header {
    width: 500px !important;
  }
}
</style>
