<template>
  <section>
    <v-row class="mt-4" v-if="etapaEditable">
      <v-col cols="12" class="mx-2">
        <p class="text-h6 secondary--text mb-0">{{ etapaContrato.etapa }}</p>
      </v-col>
    </v-row>
    <v-row class="mt-5" v-else>
      <v-col cols="12" md="8">
        <v-text-field
          label="Etapa *"
          outlined
          v-model="etapaContrato.etapa"
          :error-messages="etapaErrors"
          @input="$v.etapaContrato.etapa.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <vc-date-picker
          v-model="etapaContrato.fecha_inicio"
          mode="dateTime"
          locale="es"
          :popover="config_calendar"
          :min-date="fecha_minima"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Fecha y hora de inicio *"
              prepend-inner-icon="mdi-calendar"
              v-on="inputEvents"
              outlined
              :value="getFechaInicio"
              readonly
              :error-messages="fechaInicioErrors"
              @blur="$v.etapaContrato.fecha_inicio.$touch()"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12" md="4">
        <vc-date-picker
          v-model="etapaContrato.fecha_fin"
          mode="dateTime"
          locale="es"
          :min-date="etapaContrato.fecha_inicio"
          :popover="config_calendar"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              class="border roundend focus:outline-none focus:border-blue-300"
              label="Fecha y hora de fin *"
              prepend-inner-icon="mdi-calendar"
              v-on="inputEvents"
              outlined
              :disabled="!etapaContrato.fecha_inicio"
              :value="getFechaFin"
              readonly
              :error-messages="fechaFinErrors"
              @blur="$v.etapaContrato.fecha_fin.$touch()"
            />
          </template>
        </vc-date-picker>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea
          label="Descripción *"
          v-model="etapaContrato.descripcion"
          outlined
          :error-messages="descripcionErrors"
          @input="$v.etapaContrato.descripcion.$touch()"
        />
      </v-col>
      <v-col cols="12" md="8">
        <p>Total configurado</p>
        <progreso-porcentaje-component :avance="avanceActualComputed" />
      </v-col>
      <v-col cols="12" md="8">
        <p>Total incluyendo esta etapa</p>
        <progreso-porcentaje-component :avance="avanceActualEtapa" />
      </v-col>
      <v-col cols="12" md="12" class="my-4">
        <v-btn color="secondary" @click="abrirModal()"> Agregar OBS </v-btn>
        <p class="mt-5">Agregar OBS</p>
        <v-data-table :headers="headers" :items="lista_obs" hide-default-footer>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon @click="abrirDeleteOBS(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-row v-if="obsErrors.length" class="mx-1">
          <v-alert color="red" dark icon="mdi-information-outline">
            <v-row>
              <v-col cols="12" class="white--text">
                {{ obsErrors[0] }}
              </v-col>
            </v-row>
          </v-alert>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="mt-4">
        <v-btn outlined @click="back()"> Volver al listado </v-btn>
        <v-btn class="mx-4" color="secondary" dark @click="guardarEtapa()">
          {{ etapaEditable ? "Editar" : "Guardar" }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalAgregarObs"
      max-width="800px"
      @click:outside="showDialog = false"
    >
      <v-card>
        <v-card-title>
          <v-btn
            text
            icon
            small
            color="secondary"
            @click="modalAgregarObs = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <p class="text-h6 secondary--text">Registrar entrega</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-select
                label="OBS *"
                :items="listado_obs"
                item-value="id"
                item-text="nombre"
                clearable
                outlined
                return-object
                v-model="entregaObject.obs_contrato"
                @change="getEspecificaciones()"
                :error-messages="obsContratoErrors"
                @blur="$v.entregaObject.obs_contrato.$touch()"
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-select
                label="Especificación *"
                :items="listadoEspecificacionesFiltered"
                item-value="id"
                item-text="nombre_agrupacion"
                clearable
                outlined
                return-object
                v-model="entregaObject.especificacion_seleccionada"
                @change="getRestanteEspecificacion()"
                :error-messages="especificacionContratoErrors"
                @blur="$v.entregaObject.especificacion_seleccionada.$touch()"
              />
            </v-col>
            <v-col cols="12" md="8">
              <div class="d-flex">
                <v-text-field
                  v-model="entregaObject.cantidad"
                  label="Cantidad a entregar"
                  outlined
                  :error-messages="cantidadContratoErrors"
                  @input="$v.entregaObject.cantidad.$touch()"
                />
                <div class="d-flex flex-column mx-4 align-center">
                  <p class="mb-0 text-h4 pb-0">{{ restante }}</p>
                  <p>restantes</p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-btn color="secondary" @click="guardarObs()">Agregar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      :show="modalEliminarOBS"
      title="¿Deseas remover el OBS?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminarOBS = false"
      @confirm="deleteObs()"
    />
  </section>
</template>
<script>
import ProgresoPorcentajeComponent from "./components/ProgresoPorcentajeComponent.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { helpers, required, minValue, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ-0-9()\u00f1\u00d1 ]*$/);

function validateCantidad(value) {
  return value <= Number(this.restante);
}

export default {
  name: "crearEtapaOrdenCompra",
  components: {
    ProgresoPorcentajeComponent,
    ConfirmationDialog,
  },
  data: () => ({
    modalAgregarObs: false,
    lista_obs: [],
    fecha_minima: moment().format("YYYY/MM/DD hh:mm"),
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "nombre_agrupacion",
      },
      { text: "Cantidad", align: "start", sortable: false, value: "cantidad" },
      { text: "OBS", align: "start", sortable: false, value: "obs" },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
    config_calendar: {
      visibility: "click",
    },
    listado_obs: [],
    listado_especificaciones: [],
    fecha_inicio: null,
    fecha_fin: null,
    restante: 0,
    entregaObject: {
      cantidad: null,
      obs_contrato: null,
      especificacion_seleccionada: null,
    },
    restanteStored: null,
    id_obs_etapa_detalle: null,
    modalEliminarOBS: false,
  }),
  validations: {
    etapaContrato: {
      etapa: { required, alpha },
      descripcion: { required, alpha },
      fecha_inicio: { required },
      fecha_fin: { required },
      obs: { required },
    },
    entregaObject: {
      cantidad: { required, validateCantidad, minValue: minValue(1), numeric},
      obs_contrato: { required },
      especificacion_seleccionada: { required },
    },
  },
  computed: {
    ...mapState("procesoCompra", [
      "idOrdenCompra",
      "etapaEditable",
      "etapaContrato",
      "etapaProgreso",
    ]),
    listadoEspecificacionesFiltered() {
       return this.listado_especificaciones.filter((option) => {
         return !this.lista_obs.some((item) => item.id === option.id);
       });
    },
    avanceActualComputed() {
      return parseFloat(this.etapaProgreso.progreso) * 100;
    },
    avanceActualEtapa() {
      if (this.avanceActualComputed == 100) {
        return this.avanceActualComputed;
      } else if (this.etapaEditable) {
        return this.avanceActualComputed;
      } else {
        const sumOBS = this.lista_obs.reduce((accumulator, object) => {
          return accumulator + Number(object.cantidad);
        }, 0);

        const obsPorcentaje = sumOBS / this.etapaProgreso.total;

        return (
          (parseFloat(obsPorcentaje.toFixed(2)) +
            parseFloat(this.etapaProgreso.progreso)) *
          100
        );
      }
    },
    ///Validaciones Registro de entrega ///
    obsContratoErrors() {
      const errors = [];
      if (!this.$v.entregaObject.obs_contrato.$dirty) return errors;
      !this.$v.entregaObject.obs_contrato.required &&
        errors.push("El campo OBS es obligatorio");
      return errors;
    },
    especificacionContratoErrors() {
      const errors = [];
      if (!this.$v.entregaObject.especificacion_seleccionada.$dirty)
        return errors;
      !this.$v.entregaObject.especificacion_seleccionada.required &&
        errors.push("El campo Especificación es obligatorio");
      return errors;
    },
    cantidadContratoErrors() {
      const errors = [];
      if (!this.$v.entregaObject.cantidad.$dirty) return errors;
      !this.$v.entregaObject.cantidad.required &&
        errors.push("El campo Cantidad a entregar es obligatorio");
      !this.$v.entregaObject.cantidad.validateCantidad &&
        errors.push(
          "El campo Cantidad a entregar no puede ser mayor a la cantidad restante"
        );
      !this.$v.entregaObject.cantidad.minValue &&
        errors.push("El campo Cantidad a entregar debe ser mayor a 0");
      !this.$v.entregaObject.cantidad.numeric && errors.push("El campo Cantidad a entregar solo acepta números enteros");
      return errors;
    },
    ///Validaciones Registro de entrega ///

    ///Validaciones etapa contrato ///
    etapaErrors() {
      const errors = [];
      if (!this.$v.etapaContrato.etapa.$dirty) return errors;
      !this.$v.etapaContrato.etapa.required &&
        errors.push("El campo Etapa es es obligatorio");
      !this.$v.etapaContrato.etapa.alpha &&
        errors.push("El campo Etapa solo permite caracteres alfanuméricos");
      return errors;
    },
    fechaInicioErrors() {
      const errors = [];
      if (!this.$v.etapaContrato.fecha_inicio.$dirty) return errors;
      !this.$v.etapaContrato.fecha_inicio.required &&
        errors.push("El campo Fecha y hora de inicio es es obligatorio");
      return errors;
    },
    fechaFinErrors() {
      const errors = [];
      if (!this.$v.etapaContrato.fecha_fin.$dirty) return errors;
      !this.$v.etapaContrato.fecha_fin.required &&
        errors.push("El campo Fecha y hora de fin es es obligatorio");
      return errors;
    },
    descripcionErrors() {
      const errors = [];
      if (!this.$v.etapaContrato.descripcion.$dirty) return errors;
      !this.$v.etapaContrato.descripcion.required &&
        errors.push("El campo Fecha y hora de fin es es obligatorio");
      !this.$v.etapaContrato.descripcion.alpha &&
        errors.push(
          "El campo Descripción solo permite caracteres alfanuméricos"
        );
      return errors;
    },
    obsErrors() {
      const errors = [];
      if (!this.$v.etapaContrato.obs.$dirty) return errors;
      !this.$v.etapaContrato.obs.required &&
        errors.push("Debe agregar al menos un OBS");
      return errors;
    },
    ///Validaciones etapa contrato ///

    getFechaInicio() {
      if (this.etapaContrato.fecha_inicio) {
        return moment(this.etapaContrato.fecha_inicio).format(
          "DD/MM/YYYY hh:mm a"
        );
      } else return null;
    },
    getFechaFin() {
      if (this.etapaContrato.fecha_fin) {
        return moment(this.etapaContrato.fecha_fin).format("DD/MM/YYYY hh:mm a");
      } else return null;
    },
  },
  methods: {
    ...mapMutations("procesoCompra", [
      "setEtapaEditable",
      "clearEtapa",
      "setEtapa",
    ]),
    ...mapActions("procesoCompra", ["getEtapasContrato", "getEtapasProgreso"]),
    back() {
      this.$router.back();
      this.setEtapaEditable(false);
      this.clearEtapa();
    },
    abrirDeleteOBS(item) {
      this.id_obs_etapa_detalle = item.id_detalle_etapa;
      this.modalEliminarOBS = true;
    },
    abrirModal() {
      this.modalAgregarObs = true;
    },
    async guardarObs() {
      this.$v.entregaObject.$touch();

      if (!this.$v.entregaObject.$invalid) {
        if (this.etapaEditable) {
          const { status } =
            await this.services.ContratoService.postEtapaDetalle(
              this.etapaContrato.id,
              {
                id_sub_proceso_obs:
                  this.entregaObject.especificacion_seleccionada.id,
                cantidad: this.entregaObject.cantidad,
              }
            );
          if (status == 201) {
            const { data } =
              await this.services.ContratoService.getContratoEtapa(
                this.etapaContrato.id
              );
            this.setEtapa(data);
            this.getLitaOBSEtapa();
            this.temporalAlert({
              show: true,
              message: "OBS agregado exitosamente",
              type: "success",
            });
          }
        } else {
          let obs_row = {
            id: this.entregaObject.especificacion_seleccionada.id,
            id_sub_proceso_obs:
              this.entregaObject.especificacion_seleccionada.id,
            cantidad: this.entregaObject.cantidad,
            nombre_agrupacion:
              this.entregaObject.especificacion_seleccionada.nombre_agrupacion,
            id_obs: this.entregaObject.obs_contrato.id,
            obs: `${this.entregaObject.obs_contrato.codigo} - ${this.entregaObject.obs_contrato.nombre}`,
          };
          this.lista_obs.push(obs_row);
          this.etapaContrato.obs.push({
            id_sub_proceso_obs:
              this.entregaObject.especificacion_seleccionada.id,
            cantidad: this.entregaObject.cantidad,
          });
        }

        Object.keys(this.entregaObject).forEach((key) => {
          this.entregaObject[key] = null;
        });

        this.$v.entregaObject.$reset();
        this.modalAgregarObs = false;
        this.restante = 0;
      }
    },
    async deleteObs() {
      if (this.etapaEditable) {
        const { status } =
          await this.services.ContratoService.deleteDetalleEtapa(
            this.id_obs_etapa_detalle
          );

        if (status == 204) {
          const { data } = await this.services.ContratoService.getContratoEtapa(
            this.etapaContrato.id
          );
          this.setEtapa(data);
          this.getLitaOBSEtapa();
          this.temporalAlert({
            show: true,
            message: "OBS eliminado exitosamente",
            type: "success",
          });
        }
      } else {
        const i = this.lista_obs.indexOf(this.id_obs_etapa_detalle);
        const i2 = this.etapaContrato.obs.indexOf(this.id_obs_etapa_detalle);

        this.lista_obs.splice(i, 1);

        this.etapaContrato.obs.splice(i2, 1);
      }
      this.modalEliminarOBS = false;
    },

    async getListadoObs() {
      const { data, status } =
        await this.services.ContratoService.getContratoEtapasOBS(
          this.idOrdenCompra
        );
      if (status == 200) {
        this.listado_obs = data;
      }
    },
    async getEspecificaciones() {
      if (
        this.entregaObject.obs_contrato &&
        this.entregaObject.obs_contrato?.id
      ) {
        const { data, status } =
          await this.services.ContratoService.getContratoEtapasEspecificaciones(
            this.entregaObject.obs_contrato.id,
            { id_contrato_orden: this.idOrdenCompra }
          );
        if (status == 200) {
          this.listado_especificaciones = data;
        }
      }
    },
    async getRestanteEspecificacion() {
      const { data, status } =
        await this.services.ContratoService.getCantidadRestanteEtapa(
          this.entregaObject.especificacion_seleccionada.id
        );
      if (status == 200) {
        this.restante = data.restantes;
        this.restanteStored = data.restantes;
      }
    },
    // onlyNumeros($event) {
    //   if($event.charCode < 48 || $event.charCode > 57)
    //     $event.preventDefault()
    // },

    async guardarEtapa() {
      this.$v.etapaContrato.$touch();

      if (!this.$v.etapaContrato.$invalid) {
        this.etapaContrato.fecha_inicio = moment(
          this.etapaContrato.fecha_inicio
        ).format("YYYY/MM/DD hh:mm");
        this.etapaContrato.fecha_fin = moment(
          this.etapaContrato.fecha_fin
        ).format("YYYY/MM/DD hh:mm");

        let response = null;

        if (this.etapaEditable) {
          response = await this.services.ContratoService.updateEtapa(
            this.etapaContrato.id,
            this.etapaContrato
          );
        } else {
          response = await this.services.ContratoService.postContratoEtapa(
            this.idOrdenCompra,
            this.etapaContrato
          );
        }

        if (response.status == 201 || response.status == 204) {
          this.temporalAlert({
            show: true,
            message: `Etapa ${
              this.etapaEditable ? "editada" : "creada"
            } exitosamente`,
            type: "success",
          });
          if (!this.etapaEditable) {
            Object.keys(this.etapaContrato).forEach((key) => {
              this.etapaContrato[key] = null;
              if (key == "obs") {
                this.etapaContrato[key] = [];
              }
            });
          }
          this.getEtapasProgreso();
          this.$v.etapaContrato.$reset();
          this.lista_obs = [];
        }
      }
    },

    getLitaOBSEtapa() {
      this.lista_obs = this.etapaContrato.obs.map((col) => ({
        ...col,
        obs: `${col.codigo} - ${col.obs}`,
      }));
    },
  },
  created() {
    this.getListadoObs();
    if (this.etapaEditable) {
      this.getLitaOBSEtapa();
    }
  },
};
</script>
